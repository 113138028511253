import axios from 'axios';
import { CHAT, SENDMESSAGE } from 'src/constants/constants';
import { composeBaseURL, handleErrors, composePath } from 'src/common/commonUtils';
import { OpenAIPayload } from 'src/types/types';

// Define the base URL for your API
const BASEAPI_URL = CHAT;

// Create an Axios instance
const api = axios.create({
    baseURL: composeBaseURL(BASEAPI_URL),
    headers: { 'Content-Type': 'application/json' },
});


// API functions
export const apiSendMessage = async (payload: OpenAIPayload) => {
    try {
        const response = await api.post(composePath(SENDMESSAGE), payload);
        return response;
    } catch (error) {
        throw new Error(handleErrors(error));
    }
};