export const APPNAME = "BIVOBOT"
export const API_URL = process.env.REACT_APP_API_URL

//slice name and api end point
export const AUTH = "auth"
export const DASHBOARD = 'dashboard'
export const PROFILE = "profile"
export const MODALS = "modals"
export const HUMANOID = "humanoid"
export const CHAT = "chat"
export const MODEL = "model"
export const SETTINGS = "settings"
export const TENANT = "tenant"


//response types
export const IDLE = 'idle'
export const PENDING = 'pending'
export const FULFILLED = 'fulfilled'
export const REJECTED = 'rejected'

//message roled
export const ASSISTANT = "assistant"
export const USER = "user"
export const SYSTEM = "system"

//router paths and asyncThunk actions
export const SIGNUP = "signup";
export const SIGNIN = "signin";
export const FORGOTPASSWORD = "forgot-password";
export const RESETPASSWORD = "reset-password";

//api endpoints and asyncThunk actions
export const GETTENANT = "get-tenant"
export const SENDMESSAGE = "send-message"
export const VALIDATEPASSWORD = "validate-password"
export const SAVETENANT = "save-tenant"
export const DELETETENANT = "delete-tenant"
export const GETALLTENANTS = "get-all-tenants"
