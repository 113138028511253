import ChatMessages from './ChatMessages'
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { ChatTextArea } from './ChatTextArea';
import { ChatErrors } from './ChatErrors';

const Chat = () => {
    const { chatError, charCount } = useSelector((state: RootState) => state.chat);

    return (
        <div className='chat-wrapper'>
            <div className='chat-messages-wrapper'>
                <ChatMessages />
            </div>

            <ChatTextArea />

            <ChatErrors errorMessage={chatError} charCount={charCount} />
        </div>
    )
}

export default Chat