import axios from 'axios';
import { AUTH } from 'src/constants/constants';
import { FORGOTPASSWORD, RESETPASSWORD, SIGNIN, SIGNUP } from 'src/constants/constants';
import { composeBaseURL, handleErrors, composePath } from 'src/common/commonUtils';

// Define the base URL for your API
const BASEAPI_URL = AUTH;

// Create an Axios instance
const api = axios.create({
    baseURL: composeBaseURL(BASEAPI_URL),
    headers: { 'Content-Type': 'application/json' },
});

// Define types for the responses
interface SignUpResponse {
    email: string;
}

interface SignInResponse {
    email: string;
}

interface ResetPasswordResponse {
    message: string;
}

interface ForgetPasswordResponse {
    message: string;
}

// API functions
export const apiSignUp = async (credentials: { email: string; password: string }): Promise<SignUpResponse> => {
    try {
        const response = await api.post(composePath(SIGNUP), credentials);
        return response.data;
    } catch (error) {
        throw new Error(handleErrors(error));
    }
};

export const apiSignIn = async (credentials: { email: string; password: string }): Promise<SignInResponse> => {
    try {
        const response = await api.post(composePath(SIGNIN), credentials);
        return response.data;
    } catch (error) {
        throw new Error(handleErrors(error));
    }
};

export const apiResetPassword = async (email: string): Promise<ResetPasswordResponse> => {
    try {
        const response = await api.post(composePath(RESETPASSWORD), { email });
        return response.data;
    } catch (error) {
        throw new Error(handleErrors(error));
    }
};

export const apiForgetPassword = async (email: string): Promise<ForgetPasswordResponse> => {
    try {
        const response = await api.post(composePath(FORGOTPASSWORD), { email });
        return response.data;
    } catch (error) {
        throw new Error(handleErrors(error));
    }
};
