import { memo, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import BotMessage from "./Message/BotMessage";
import UserMessage from "./Message/UserMessage";
import { ChatMessage } from "src/store/slices/chatSlice";
import { ASSISTANT } from "src/constants/constants";

type ChatMessagesProps = {

};

const ChatMessages = memo(function ChatMessages({ }: ChatMessagesProps) {
    const { messages } = useSelector((state: RootState) => state.chat)
    const endRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (endRef.current) {
            endRef.current.scrollIntoView({ behavior: "auto" });
        }
    }, [messages]);

    return (
        <>
            {messages?.map((message: ChatMessage, index) => {
                if (message.role === ASSISTANT) {
                    return <BotMessage message={message} key={message.role + index} />;
                } else {
                    return <UserMessage message={message} key={message.role + index} />;
                }
            })}
            <div ref={endRef} /> {/* Invisible div for auto scrolling purposes */}
        </>
    );
});

export default ChatMessages