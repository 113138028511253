import { ReactNode } from 'react'

type MessageWrapperProps = {
  classes?: string
  name: string | null
  children: ReactNode
}
const MessageWrapper = ({ name, children, classes }: MessageWrapperProps) => {
  return (
    <div className={classes}>
      <div className="message-wrapper">
        <span className="message-name">{name}:</span>
        {children}
      </div>
    </div>
  )
}

export default MessageWrapper