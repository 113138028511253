import { faCog, faPowerOff, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { SETTINGS, TENANT } from 'src/constants/constants'
import { deleteTenant, getAllTenants } from 'src/store/slices/modelSlice'
import { RootState, useAppDispatch } from 'src/store/store'
import ValidatePassword from './ValidatePassword'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { handleIsValidated } from 'src/store/slices/settingsSlice'

const Settings = () => {
    const dispatch = useAppDispatch()
    const { isValidated } = useSelector((state: RootState) => state.settings)
    const { tenants } = useSelector((state: RootState) => state.model)

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [selectedTenant, setSelectedTenant] = useState<string>('')

    const handleDeleteTenant = (tenantName: string) => {
        setShowDeleteModal(true)
        setSelectedTenant(tenantName)
    }

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false)
        setSelectedTenant('')
    }

    const handleLogout = () => {
        dispatch(handleIsValidated(false))
    }

    useEffect(() => {
        if (isValidated) {
            dispatch(getAllTenants())
        }
    }, [isValidated])


    const LinkToModel = ({ tenantName }: { tenantName: string }) => {
        return (
            <div className='hstack gap-2'>
                <a href={`/${TENANT}/${tenantName}`} className='text-uppercase'>{tenantName}</a>
                <Link to={`/${SETTINGS}/${tenantName}`} className='text-uppercase'><FontAwesomeIcon icon={faCog} /></Link>
                <button className='btn p-0 text-danger' onClick={() => handleDeleteTenant(tenantName)}><FontAwesomeIcon icon={faTrash} /></button>
            </div>
        )
    }

    return (
        <div className='container mt-5'>
            {isValidated ?
                <div>
                    <div className='hstack justify-content-between gap-2 flex-wrap'>
                        <h2>Tenants List</h2>
                        <div className='hstack gap-4 flex-wrap'>
                            <Link to={`/${SETTINGS}/new`} className='btn btn-primary'>Create new tenant</Link>
                            <button className='btn p-0' onClick={handleLogout}><FontAwesomeIcon icon={faPowerOff} title='logout' aria-label='logout' /></button>
                        </div>
                    </div>
                    <hr />
                    <div className='hstack gap-4 flex-wrap'>
                        {tenants && tenants.map((tenantName: string, index: number) => {
                            return <LinkToModel tenantName={tenantName} key={tenantName + index} />
                        })}
                    </div>
                    <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Are you sure you want to delete this tenant ?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='hstack justify-content-center gap-4'>
                                <button className='btn btn-secondary' onClick={handleCloseDeleteModal}>No, Go Back.</button>
                                <button className='btn btn-danger' onClick={() => { dispatch(deleteTenant(selectedTenant)); handleCloseDeleteModal() }}>Yes, Delete.</button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
                :
                <ValidatePassword />
            }
        </div>
    )
}

export default Settings