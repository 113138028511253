import { createSlice } from '@reduxjs/toolkit';
import { MODALS } from 'src/constants/constants';

// Define types
interface ModalsState {

}

// Define the initial state
const initialState: ModalsState = {

};

// Create async thunks for authentication operations


// Create the slice
const modalsSlice = createSlice({
    name: MODALS,
    initialState,
    reducers: {
        // Optional synchronous actions can be defined here
    },
    extraReducers: () => { },
});

export default modalsSlice.reducer;
