import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { composePath, composeThunkResponse, isValidColor } from 'src/common/commonUtils'
import { models } from 'src/config/modelConfig'
import { FULFILLED, GETTENANT, MODEL, PENDING, REJECTED, SAVETENANT, SETTINGS } from 'src/constants/constants'
import { voices } from 'src/constants/voices'
import { colorOption, getModelByTenantName, imageOption, initialModelInfo, ModelInfoState, saveTenant, setModelInfo, skyboxOption } from 'src/store/slices/modelSlice'
import { RootState, useAppDispatch } from 'src/store/store'
import * as Yup from 'yup'


// change this to '' if needed
const defaultImage = 'https://images.unsplash.com/photo-1642336186832-522a31a4d12f?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
const defaultBgColor = "#ffffff"
const defaultSkybox = 'https://playground.babylonjs.com/textures/environment.env'

export interface ModelSettings extends ModelInfoState {
    imageOptionInput: string,
    colorCode: string,
    colorOptionInput: string,
    skyboxOptionInput: string
}

const ModelSettings = () => {
    const dispatch = useAppDispatch()
    let navigate = useNavigate()
    let { tenant } = useParams()
    let createMode = tenant === 'new'

    const { modelInfo, modelStatus } = useSelector((state: RootState) => state.model)
    const { pdfName, tenantName } = modelInfo


    const defaultValues: ModelSettings = {
        ...modelInfo,
        imageOptionInput: defaultImage,
        colorCode: defaultBgColor,
        colorOptionInput: defaultBgColor,
        skyboxOptionInput: defaultSkybox
    }

    const [initialValues, setInitialValues] = useState<ModelSettings>(defaultValues)

    const validationSchema = Yup.object().shape({
        model: Yup.string().required("This field is required").oneOf(Object.keys(models), "Invalid selection"),
        voice: Yup.string().required("This field is required").oneOf(Object.keys(voices), "Invalid selection"),
        role: Yup.string().required("This field is required"),
        prompt: Yup.string().required("This field is required"),
        initialMessage: Yup.string().required("This field is required"),
        tenantName: Yup.string().required("This field is required"),

        backgroundOptions: Yup.string().required("This field is required"),
        imageOptionInput: Yup.string().when('backgroundOptions', {
            is: (value: any) => value === imageOption,
            then: (schema: any) => schema.required("This field is required")
        }),
        colorCode: Yup.string().when('backgroundOptions', {
            is: (value: string) => value === colorOption,
            then: (schema: any) => schema.required("This field is required")
                .test(
                    "Check Color",
                    "Please enter a valid color code",
                    (value: string) => isValidColor(value)
                )
        }),
        colorOptionInput: Yup.string().when('backgroundOptions', {
            is: (value: string) => value === colorOption,
            then: (schema: any) => schema.required("This field is required")
        }),
        skyboxOptionInput: Yup.string().when('backgroundOptions', {
            is: (value: string) => value === skyboxOption,
            then: (schema: any) => schema.required("This field is required")
                .test(
                    "Check Extension",
                    "Please enter a valid url (.env extension)",
                    (value: string) => value.slice(-4) === ".env"
                )
        }),
    })




    const handleSubmit = (formValue: ModelSettings) => {
        console.log("formValue", formValue);
        const {
            model,
            voice,
            role,
            prompt,
            pdfName,
            assistant_id,
            initialMessage,
            speechRecognitionLanguageCode,
            tenantName,
            backgroundOptions,
            imageOptionInput,
            colorOptionInput,
            skyboxOptionInput } = formValue

        let background: string = '';
        if (backgroundOptions === imageOption) {
            background = imageOptionInput
        }
        if (backgroundOptions === colorOption) {
            background = colorOptionInput
        }
        if (backgroundOptions === skyboxOption) {
            background = skyboxOptionInput
        }

        const formData: ModelInfoState = {
            model: model,
            voice: voice,
            role: role,
            prompt: prompt,
            pdfName: pdfName,
            assistant_id: assistant_id,
            initialMessage: initialMessage,
            speechRecognitionLanguageCode: speechRecognitionLanguageCode,
            background: background,
            tenantName: tenantName,
            backgroundOptions: backgroundOptions
        }

        console.log("formData", formData);


        dispatch(saveTenant(formData)).then((res: any) => {
            if (res.type === composeThunkResponse([MODEL, SAVETENANT, FULFILLED])) {
                navigate('/')
            }
        })
    }

    useEffect(() => {
        if (tenant) {
            if (createMode) {
                dispatch(setModelInfo(initialModelInfo))
            } else {
                dispatch(getModelByTenantName(tenant)).then((res: any) => {
                    if (res.type === composeThunkResponse([MODEL, GETTENANT, FULFILLED])) {
                        const bgOptions = res.payload.backgroundOptions
                        const bgValue = res.payload.background
                        console.log("res", res);


                        const setOptionValue = (option: string, defaultValue: string) => {
                            return bgOptions === option ? bgValue : defaultValue
                        }

                        setInitialValues({
                            ...res.payload,
                            imageOptionInput: setOptionValue(imageOption, defaultImage),
                            colorCode: setOptionValue(colorOption, defaultBgColor),
                            colorOptionInput: setOptionValue(imageOption, defaultBgColor),
                            skyboxOptionInput: setOptionValue(skyboxOption, defaultSkybox),
                        })

                    }
                    if (res.type === composeThunkResponse([MODEL, GETTENANT, REJECTED])) {
                        navigate(composePath(SETTINGS))
                    }
                })
            }
        }
    }, [tenant, createMode, dispatch])


    const syncColorCode = (setFieldValue: (field: string, value: any) => void) =>
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.currentTarget.value
            setFieldValue("colorCode", value)
            setFieldValue("colorOptionInput", value)
        }

    return (
        <div className='container my-5'>
            <div className='hstack justify-content-between gap-2 flex-wrap'>
                <div className='hstack gap-2'>
                    <Link to={composePath(SETTINGS)} className='btn btn-primary'>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Link>
                    <h2 className='mb-1'> {createMode ? "Create New" : 'Model Settings'}</h2>
                </div>
                <h3 className='text-uppercase mb-0'>{tenantName}</h3>
            </div>
            <hr />
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, values }) => (
                    <Form className='vstack gap-4'>
                        <div>
                            <label htmlFor="model">Select a Model</label>
                            <Field as="select" name="model" className="form-control">
                                {Object.keys(models).map((model: string) =>
                                    <option key={model} value={model}>{model}</option>
                                )}
                            </Field>
                            <ErrorMessage name="model" component="div" className="error" />
                        </div>
                        <div>
                            <label htmlFor="voice">Select a Voice</label>
                            <Field as="select" name="voice" className="form-control">
                                {Object.keys(voices).map((voice: string) =>
                                    <option key={voice} value={voice}>{voice}</option>
                                )}
                            </Field>
                            <ErrorMessage name="voice" component="div" className="error" />
                        </div>
                        <div>
                            <label htmlFor="role">
                                Role
                            </label>
                            <Field
                                id="role"
                                className="form-control"
                                type="text"
                                name="role"
                            />
                            <ErrorMessage name="role" component="div" className="error" />
                        </div>
                        <div>
                            <label htmlFor="prompt">Prompt</label>
                            <Field component="textarea" rows={5} name="prompt" className="form-control"></Field>
                            <ErrorMessage name="prompt" component="div" className="error" />
                        </div>

                        {/* @todo: pdf upload pending */}
                        {/* <div>
                            <label htmlFor="pdfName">Upload Pdf</label>
                            <input
                                className="form-control"
                                id="pdfName"
                                name="pdfName"
                                type="file"
                                accept="image/*,.pdf"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue("pdfName", e.currentTarget.files ? e.currentTarget.files[0] : null);
                                }}
                            />
                            <div>{pdfName}</div>
                            <ErrorMessage name="pdfName" component="div" className="error" />
                        </div> */}

                        <div className='vstack gap-2'>
                            <label htmlFor="backgroundOptions">Background</label>
                            <div className='hstack gap-3'>
                                <label className='hstack gap-2'>
                                    <Field type="radio" name="backgroundOptions" value={imageOption} />
                                    <span>Image url</span>
                                </label>
                                <label className='hstack gap-2'>
                                    <Field type="radio" name="backgroundOptions" value={colorOption} />
                                    <span>Color</span>
                                </label>
                                <label className='hstack gap-2'>
                                    <Field type="radio" name="backgroundOptions" value={skyboxOption} />
                                    <span>Skybox url</span>
                                </label>
                                <ErrorMessage name="backgroundOptions" component="div" />
                            </div>

                            {values.backgroundOptions === imageOption &&
                                <>
                                    <Field
                                        className="form-control"
                                        type="text"
                                        name="imageOptionInput"
                                        placeholder="enter image url"
                                    />
                                    <ErrorMessage name="imageOptionInput" component="div" className="error" />
                                </>
                            }

                            {values.backgroundOptions === colorOption &&
                                <div className='hstack gap-3'>
                                    <Field
                                        className="form-control"
                                        type="text"
                                        name="colorCode"
                                        onChange={syncColorCode(setFieldValue)}
                                    />
                                    <Field
                                        className="size-48"
                                        type="color"
                                        name="colorOptionInput"
                                        onChange={syncColorCode(setFieldValue)}
                                    />
                                    <ErrorMessage name="colorCode" component="div" className="error" />
                                </div>
                            }

                            {values.backgroundOptions === skyboxOption &&
                                <>
                                    <Field
                                        className="form-control"
                                        type="text"
                                        name="skyboxOptionInput"
                                        placeholder="enter skybox file url (.env extension)"
                                    />
                                    <ErrorMessage name="skyboxOptionInput" component="div" className="error" />
                                </>
                            }
                        </div>

                        <div>
                            <label htmlFor="tenantName">
                                Tenant name
                            </label>
                            <Field
                                id="tenantName"
                                className="form-control"
                                type="text"
                                name="tenantName"
                                disabled={!createMode}
                            />
                            <ErrorMessage name="tenantName" component="div" className="error" />
                        </div>
                        <button className='btn btn-primary mt-5' type='submit' disabled={modelStatus === PENDING}>
                            Submit
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ModelSettings