import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useState } from 'react'
import { validatePassword } from 'src/store/slices/settingsSlice'
import { useAppDispatch } from 'src/store/store'
import * as Yup from 'yup'

const ValidatePassword = () => {
    const dispatch = useAppDispatch()

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const passwordInitialValues = { password: '' }
    const passwordValidationSchema = Yup.object().shape({
        password: Yup.string().required("This field is required"),
    })

    const handleSubmitPassword = (formValue: any) => {
        const { password } = formValue
        dispatch(validatePassword(password))
    }

    return (
        <div>
            <Formik
                initialValues={passwordInitialValues}
                validationSchema={passwordValidationSchema}
                onSubmit={handleSubmitPassword}
            >
                <Form className='vstack gap-3'>
                    <label htmlFor="password">Enter password to access settings</label>
                    <div className='position-relative'>
                        <Field type={showPassword ? "text" : "password"} name="password" className="form-control pe-5" />
                        <button className='btn d-flex align-items-center p-0 position-absolute translate-middle-y top-50 end-0 me-2' type='button' onClick={() => setShowPassword(!showPassword)}>
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </button>
                    </div>
                    <ErrorMessage name="password" component="div" className="error" />
                    <button className='btn btn-primary' type='submit'>Submit</button>
                </Form>
            </Formik>
        </div>
    )
}

export default ValidatePassword