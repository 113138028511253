import { createSlice } from '@reduxjs/toolkit';
import { DASHBOARD } from 'src/constants/constants';

// Define types
interface DashboardState {

}

// Define the initial state
const initialState: DashboardState = {

};

// Create async thunks for authentication operations


// Create the slice
const dashboardSlice = createSlice({
    name: DASHBOARD,
    initialState,
    reducers: {
        // Optional synchronous actions can be defined here
    },
    extraReducers: () => { },
});

export default dashboardSlice.reducer;
