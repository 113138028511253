import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import authReducer from "src/store/slices/authSlice"
import dashboardReducer from "src/store/slices/dashboardSlice"
import modalsReducer from "src/store/slices/modalsSlice"
import chatReducer from "src/store/slices/chatSlice"
import modelReducer from "src/store/slices/modelSlice"
import settingsReducer from "src/store/slices/settingsSlice"
import { useDispatch } from 'react-redux';


//Reducers 
const rootReducer = combineReducers({
    auth: authReducer,
    dashboard: dashboardReducer,
    modals: modalsReducer,
    chat: chatReducer,
    model: modelReducer,
    settings: settingsReducer,
})

//persist configuration
const persistConfig: any = {
    key: 'root',
    storage: storage,
    blacklist: ['chat', 'model'],
    // transforms: [
    //     createBlacklistFilter('chat', ['isLoadingMessage'])
    // ]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

//store configuration
export const store = configureStore({
    reducer: persistedReducer,

    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
    }),
    devTools: process.env.NODE_ENV !== 'production',
})

export const persistor = persistStore(store)
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
