import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { composeThunkAction } from 'src/common/commonUtils';
import { APPNAME, SETTINGS, VALIDATEPASSWORD } from 'src/constants/constants';
import { apiValidatePassword } from 'src/services/model.service';
// Define types

interface SettingsState {
    appName: string
    isLoading: boolean
    isVolumeOn: boolean
    initErrorMessage: string
    aboutModalIsOpen: boolean
    settingsModalIsOpen: boolean
    isValidated: boolean
}

// Define the initial state
const initialState: SettingsState = {
    appName: APPNAME,
    isLoading: false,
    isVolumeOn: true,
    initErrorMessage: "",
    aboutModalIsOpen: false,
    settingsModalIsOpen: false,
    isValidated: false,
};


// Create async thunks for authentication operations
export const validatePassword = createAsyncThunk(
    composeThunkAction(SETTINGS, VALIDATEPASSWORD),
    async (password: string, { rejectWithValue }) => {
        try {
            const data = await apiValidatePassword(password);
            return data;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);


// Create the slice
const settingsSlice = createSlice({
    name: SETTINGS,
    initialState,
    reducers: {
        handleLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoading = payload;
        },
        handleVolumn: (state, { payload }: PayloadAction<boolean>) => {
            state.isVolumeOn = payload;
        },
        handleInitErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.initErrorMessage = payload;
        },
        handleIsValidated: (state, { payload }: PayloadAction<boolean>) => {
            state.isValidated = payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(validatePassword.pending, (state) => {
            })
            .addCase(validatePassword.fulfilled, (state) => {
                state.isValidated = true
            })
            .addCase(validatePassword.rejected, (state, action) => {
                toast.error(action.payload as string)
            })
    },
});

export const { handleLoading, handleVolumn, handleInitErrorMessage, handleIsValidated } = settingsSlice.actions

export default settingsSlice.reducer;
