import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { composePath } from './common/commonUtils';
import { SETTINGS, TENANT } from './constants/constants';
import Dashboard from './pages/Dashboard/Dashboard';
import Settings from './pages/Settings/Settings';
import ModelSettings from './pages/Settings/ModelSettings';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from './store/store';
import NotFound from './pages/NotFound/NotFound';

function App() {
  const { isValidated } = useSelector((state: RootState) => state.settings)

  const PrivateRoute = () => {
    return isValidated ? <Outlet /> : <Navigate to="/" />
  }

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={2500}
      />
      <BrowserRouter>
        {/* <iframe src='http://localhost:3000/' width={300} height={600} className='iframe'></iframe> */}
        <Routes>
          <Route path="*" caseSensitive element={<NotFound />} />
          <Route path={`/${TENANT}/:tenant`} element={<Dashboard />} />
          <Route path="/" caseSensitive element={<Settings />} />
          <Route path={composePath(SETTINGS)} element={<Settings />} />
          <Route element={<PrivateRoute />}>
            <Route path={`/${SETTINGS}/:tenant`} element={<ModelSettings />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
