import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AUTH, REJECTED, IDLE, PENDING, FULFILLED, FORGOTPASSWORD, RESETPASSWORD, SIGNIN, SIGNUP } from 'src/constants/constants';
import { composeThunkAction } from 'src/common/commonUtils';
import { apiForgetPassword, apiResetPassword, apiSignIn, apiSignUp } from 'src/services/auth.service';

// Define types
interface AuthState {
    user: { email: string } | null;
    authStatus: 'idle' | 'pending' | 'fulfilled' | 'rejected';
    authError: string | null;
}

// Define the initial state
const initialState: AuthState = {
    user: null,
    authStatus: IDLE,
    authError: null,
};

// Create async thunks for authentication operations
export const signIn = createAsyncThunk(
    composeThunkAction(AUTH, SIGNIN),
    async (credentials: { email: string; password: string }, { rejectWithValue }) => {
        try {
            const data = await apiSignIn(credentials);
            return data;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const signUp = createAsyncThunk(
    composeThunkAction(AUTH, SIGNUP),
    async (credentials: { email: string; password: string }, { rejectWithValue }) => {
        try {
            const data = await apiSignUp(credentials);
            return data;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const resetPassword = createAsyncThunk(
    composeThunkAction(AUTH, RESETPASSWORD),
    async (email: string, { rejectWithValue }) => {
        try {
            const data = await apiResetPassword(email);
            return data;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

export const forgetPassword = createAsyncThunk(
    composeThunkAction(AUTH, FORGOTPASSWORD),
    async (email: string, { rejectWithValue }) => {
        try {
            const data = await apiForgetPassword(email);
            return data;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
);

// Create the slice
const authSlice = createSlice({
    name: AUTH,
    initialState,
    reducers: {
        // Optional synchronous actions can be defined here
        logout: (state) => {
            state.user = null
            sessionStorage.clear()
            localStorage.clear()
        },
        signInDemo: (state) => {
            state.user = { email: "jun@j.com" }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(signUp.pending, (state) => {
                state.authStatus = PENDING;
                state.authError = null; // Clear the error when the request is pending
            })
            .addCase(signUp.fulfilled, (state, action: PayloadAction<{ email: string }>) => {
                state.authStatus = FULFILLED;
                state.user = { email: action.payload.email };
            })
            .addCase(signUp.rejected, (state, action) => {
                state.authStatus = REJECTED;
                state.authError = action.payload as string; // Use the custom error message
            })
            .addCase(signIn.pending, (state) => {
                state.authStatus = PENDING;
                state.authError = null; // Clear the error when the request is pending
            })
            .addCase(signIn.fulfilled, (state, action: PayloadAction<{ email: string }>) => {
                state.authStatus = FULFILLED;
                state.user = { email: action.payload.email };
            })
            .addCase(signIn.rejected, (state, action) => {
                state.authStatus = REJECTED;
                state.authError = action.payload as string; // Use the custom error message
            })
            .addCase(resetPassword.pending, (state) => {
                state.authStatus = PENDING;
                state.authError = null; // Clear the error when the request is pending
            })
            .addCase(resetPassword.fulfilled, (state) => {
                state.authStatus = FULFILLED;
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.authStatus = REJECTED;
                state.authError = action.payload as string; // Use the custom error message
            })
            .addCase(forgetPassword.pending, (state) => {
                state.authStatus = PENDING;
                state.authError = null; // Clear the error when the request is pending
            })
            .addCase(forgetPassword.fulfilled, (state) => {
                state.authStatus = FULFILLED;
            })
            .addCase(forgetPassword.rejected, (state, action) => {
                state.authStatus = REJECTED;
                state.authError = action.payload as string; // Use the custom error message
            });
    },
});

export const { logout, signInDemo } = authSlice.actions
export default authSlice.reducer;
