const WebGLNotSupported = () => {
    return (
        <div className='vstack align-items-center gap-3'>
            <div className='fw-600 fs-5'>WebGL not supported :(</div>
            <p className='mb-1'>WebGL is necessary to play this game.</p>
            <div className='fw-600 fs-5'>How can I fix this?</div>
            <p className='mb-1'>
                It&apos;s hard to pinpoint an exact cause and fix. My suggestion would be to search online
                for `&quot;`How to enable WebGL in`&quot;` followed by the device and/or browser you&apos;re
                using.
            </p>
            <p className='mb-1'>
                For example: `&quot;`How to enable WebGL in iPhone Safari`&quot;`
            </p>
        </div>
    )
}

export default WebGLNotSupported