
import React, { ReactNode, createContext, useContext, useRef } from "react";
import { Humanoid } from "src/common/plugins/Babylon/Humanoid";
import { CurrentSoundRef, HumanoidRef } from "src/types/types";

type RefContextProps = {
    humanoidRef: HumanoidRef;
    currentSoundRef: CurrentSoundRef;
};

const defaultRefContext: RefContextProps = {
    humanoidRef: { current: null },
    currentSoundRef: { current: null },
};

const RefContextContext = createContext<RefContextProps>(defaultRefContext);

interface RefContextProviderProps {
    children: ReactNode;
}

const RefContextProvider: React.FC<RefContextProviderProps> = ({ children }) => {
    const humanoidRef = useRef<Humanoid | null>(null);
    const currentSoundRef = useRef<HTMLAudioElement | null>(null);

    return (
        <RefContextContext.Provider value={{ humanoidRef, currentSoundRef }}>
            {children}
        </RefContextContext.Provider>
    );
};

const useRefContext = (): RefContextProps => {
    const context = useContext(RefContextContext);
    if (!context) {
        throw new Error('useSoundContext must be used within a SoundProvider');
    }
    return context;
};


export { RefContextProvider, useRefContext };
