import { ChatMessage } from "src/store/slices/chatSlice";
import MessageWrapper from "./MessageWrapper";

type UserMessageProps = {
    message: ChatMessage;
};
const UserMessage = ({ message }: UserMessageProps) => {
    const lines = message.content.split("\n");

    return (
        <MessageWrapper name="You" classes={'d-flex justify-content-end'}>
            {lines.map((line, lineIndex) => {
                return <p key={lineIndex} className="message-content">{line === "" ? <br /> : line}</p>;
            })}
        </MessageWrapper>
    );
};
export default UserMessage