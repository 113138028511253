import { v3 } from "src/common/plugins/Babylon/utils";
import { CameraConfig, Model, ModelConfig } from "src/types/types";

export const MAX_WORD_SUGGESTION = 60;

export const OPENAI_TIMEOUT_MILLISECONDS = 600000;

export const UPLOAD_PDF_ENDPOINT = `${process.env.REACT_APP_API_URL}upload`;

export const MAX_CHARS = 300;

export const CORNER_ICON_SIZE = 24;

export const MAIN_CAMERA_NAME = "MainCamera";

export const DEFAULT_INITIAL_MESSAGE = "Hey, how's it going?";

export const DEFAULT_ROLE = 'Financial Analyst Assistant';

export const DEFAULT_PROMPT = `You are Forencia, a pioneering economist known for your structuralist approach and dependency theory. Your key concepts include:
1. Center-Periphery Model: Explain the global economic structure and its impact on developing countries.
2. Terms of Trade: Discuss the deterioration of terms of trade for primary commodity exporters.
3. Industrialization: Advocate for the industrialization of developing countries.
4. Economic Policy: Suggest policies for developing nations to achieve sustainable growth.
5. Critique of Neoclassical Economics: Highlight the limitations of neoclassical economics for developing countries.
Make sure not to mention your role as an AI or the character you are portraying. 
Keep your responses concise, no longer than ${MAX_WORD_SUGGESTION} words per response. 
Engage in a lively and positive conversation with the user.`;

export const DEFAULT_SPEECH_RECOGNITION_LANGUAGE_CODE = "en-US";

export const DEFAULT_VOICE = "en-GB-Standard-A";

export const DEFAULT_MODEL: Model = "forencia";

export const DEFAULT_BACKGROUND: any = '';

// assisstant id which is generated from python when upload a pdf
export const DEFAULT_ASSISTANT_ID = "asst_lizeiZyFxfoXfdizzBH39hkE";

export const defaultCameraConfig: CameraConfig = {
    alpha: Math.PI / 2,
    beta: Math.PI / 2.5,
    radius: 2.5,
    target: v3(0, 0.7, 0),
};

const defaultConfig: ModelConfig = {
    cameraConfig: defaultCameraConfig,
    voice: "en-US-Neural2-H",
    initialAnimation: "idle3_hand_hips",
    faceMeshName: "Face",
    morphTargets: {
        mouthMovement: "Face.M_F00_000_00_Fcl_MTH_A",
        leftEyeClose: "Face.M_F00_000_00_Fcl_EYE_Close_L",
        rightEyeClose: "Face.M_F00_000_00_Fcl_EYE_Close_R",
    },
    idleAnimations: ["idle1", "idle2", "idle3_hand_hips"],
    talkingBodyAnimations: ["talking1", "talking2_head_shake", "talking3"],
    positionOffset: v3(0, 0.015, 0),
};

export const models = {
    vroid_girl2: defaultConfig,
    samuel_adams: {
        cameraConfig: { ...defaultCameraConfig, radius: 4.5 },
        voice: "en-GB-Standard-D",
        initialAnimation: "Idle01",
        faceMeshName: "Face_primitive0",
        morphTargets: {
            mouthMovement: "Face.M_F00_000_00_Fcl_MTH_A",
            leftEyeClose: "Face.M_F00_000_00_Fcl_EYE_Close",
            rightEyeClose: "Face.M_F00_000_00_Fcl_EYE_Close",
        },
        idleAnimations: ["Idle01", "Idle02", "Idle03"],
        talkingBodyAnimations: ["Talking01", "Talking02", "Talking03"],
        positionOffset: v3(0, 0, 0),
    },
    forencia: {
        cameraConfig: { ...defaultCameraConfig, target: v3(0, 0.9, 0) },
        voice: "en-GB-Standard-A",
        initialAnimation: "idle3_hand_hips",
        faceMeshName: "Face_primitive0",
        morphTargets: {
            mouthMovement: "Face.M_F00_000_00_Fcl_MTH_A",
            leftEyeClose: "Face.M_F00_000_00_Fcl_EYE_Close",
            rightEyeClose: "Face.M_F00_000_00_Fcl_EYE_Close",
        },
        idleAnimations: ["idle1", "idle2", "idle3_hand_hips"],
        talkingBodyAnimations: ["talking1", "talking3"],
        positionOffset: v3(0, 0.015, 0),
    },
    vest_dude: {
        ...defaultConfig,
        morphTargets: {
            mouthMovement: "mouth_open",
        },
        faceMeshName: "rp_eric_rigged_001_geo",
        cameraConfig: {
            alpha: Math.PI / 2,
            beta: Math.PI / 2.5,
            radius: 3,
            target: v3(0, 0.9, 0),
        },
        positionOffset: v3(0, 0.03, 0),
    },
    sucess: {
        ...defaultConfig,
        morphTargets: {
            mouthMovement: "Mouth",
            leftEyeClose: "left_eye",
            rightEyeClose: "right_eye",
        },
        faceMeshName: "Object.4",
        cameraConfig: {
            alpha: Math.PI / 2,
            beta: Math.PI / 2.5,
            radius: 3,
            target: v3(0, 0.9, 0),
        },
        positionOffset: v3(0, 0.03, 0),
    },
};