import { faCircleNotch, faMicrophone, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { ASSISTANT, CHAT, FULFILLED, PENDING, REJECTED, SENDMESSAGE, USER } from "src/constants/constants";
import { MAX_CHARS } from "src/config/modelConfig";
import { ChatMessage, handleAbortController, handleCharCount, handleChatErrorMessage, handleIsLoadingMessage, handleNewMessage, sendMessagee } from "src/store/slices/chatSlice";
import { RootState, useAppDispatch } from "src/store/store";
import { BotResponse, OpenAIPayload, SpeechRecognitionLanguageCode } from "src/types/types";
import { composeThunkResponse } from "src/common/commonUtils";
import { useRefContext } from "../Context/RefContextProvider";


export const ChatTextArea = () => {
    const dispatch = useAppDispatch()
    const { currentSoundRef, humanoidRef } = useRefContext();

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const { messages, isLoadingMessage, abortController } = useSelector((state: RootState) => state.chat);
    const { modelInfo } = useSelector((state: RootState) => state.model);
    const { isVolumeOn } = useSelector((state: RootState) => state.settings);
    const { voice, prompt, assistant_id, speechRecognitionLanguageCode } = modelInfo


    const {
        transcript,
        listening,
        resetTranscript,
        isMicrophoneAvailable,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();


    const hasText = textAreaRef.current ? textAreaRef.current.value.length > 0 : false;



    const listen = async (language: SpeechRecognitionLanguageCode): Promise<void> => {
        await SpeechRecognition.startListening({
            continuous: false,
            language,
        });
    };

    const validateMessageLength = (
        newMessage: ChatMessage,
    ): boolean => {
        if (newMessage.content.length > MAX_CHARS) {
            dispatch(handleChatErrorMessage(`Please enter a message with ${MAX_CHARS} characters or less.`))
            return false;
        }
        return true;
    };

    const handleBotResponse = async (
        response: BotResponse
    ) => {
        const textContent = response.text
        const audioContent = response.audio

        const newMessage: ChatMessage = { content: textContent, role: ASSISTANT }
        dispatch(handleNewMessage(newMessage))

        var audio = new Audio(`data:audio/mpeg;base64,${audioContent}`);

        currentSoundRef.current?.pause();

        humanoidRef.current?.talkAnimationEnd("Received new audio");

        if (!audio) {
            return;
        }

        if (!isVolumeOn) {
            audio.volume = 0;
        }

        const onAudioEnd = () => {
            humanoidRef.current?.talkAnimationEnd("onAudioEnd");
        };

        // XXX: pause event is emitted after the pause() method is called or BEFORE an ended or seeking event
        // newAudio.addEventListener("pause", onAudioEnd);
        audio.addEventListener("ended", onAudioEnd);

        audio.play();

        currentSoundRef.current = audio;
        humanoidRef.current?.talkAnimationStart();
    };

    const sendPostRequestWithMultipleMessages = async (
        messagesToSendToBackend: ChatMessage[],
    ) => {
        let errorMessage = "";

        if (isLoadingMessage && abortController) {
            console.log("Aborting previous request.");
            abortController.abort("New request sent - aborting previous.");
        }

        try {
            const abortController = new AbortController();
            // const timeoutId = setTimeout(() => {
            //   console.log("Request took too long. Aborting.");
            //   abortController.abort("Request took too long. Aborting.");
            // }, OPENAI_TIMEOUT_MILLISECONDS);


            dispatch(handleIsLoadingMessage(true))
            dispatch(handleAbortController(abortController))

            const messagesData = messagesToSendToBackend.map((message) => {
                return { content: message.content, role: message.role };
            })


            const initialBotMessage: ChatMessage = {
                content: prompt,
                role: ASSISTANT,
            };

            messagesData.unshift(initialBotMessage);

            const payload: OpenAIPayload = {
                // model: "gpt-4o",//gpt-3.5-turbo
                messages: messagesData,
                assistant_id,
                voice: voice,
                // temperature: 0.7,
                // top_p: 1,
                // frequency_penalty: 0,
                // presence_penalty: 0,
                // max_tokens: 4096,
                // n: 1,
            };

            //async await for sendbutton loading
            await dispatch(sendMessagee(payload)).then(async (response: any) => {
                console.log("sendMessage", response)
                switch (response.type) {
                    case composeThunkResponse([CHAT, SENDMESSAGE, PENDING]):
                        console.log(response.payload)
                        break
                    case composeThunkResponse([CHAT, SENDMESSAGE, FULFILLED]):
                        console.log(response.payload)
                        await handleBotResponse(response.payload.data);
                        break
                    case composeThunkResponse([CHAT, SENDMESSAGE, REJECTED]):
                        console.log(response.payload)
                        break
                    default:
                        console.log('unknown error occured')
                }
            })
        } catch (error) {
            console.log("CHAT API ERROR: ", error);
            errorMessage = "Error: something went wrong.";

            if (error instanceof Error) {
                if (error.message.includes("abort")) {
                    errorMessage =
                        "Request aborted due to timeout. This could be due to an incorrect OpenAI API key or internet connectivity issues.";
                } else {
                    errorMessage = error.message;
                }
            }
        }

        dispatch(handleChatErrorMessage(errorMessage))
        dispatch(handleIsLoadingMessage(false))
    };

    const sendChatMessage = async () => {
        if (isLoadingMessage) {
            return;
        }


        const newMessage: ChatMessage = {
            content: textAreaRef?.current?.value || "",
            role: USER,
        };

        if (textAreaRef?.current && newMessage.content) {
            if (!validateMessageLength(newMessage)) return;

            textAreaRef.current.value = "";
            textAreaRef.current.focus();

            dispatch(handleNewMessage(newMessage))


            // all messages to the backend: the previous message and the new message.
            const messagesToSendToBackend = [...messages, newMessage];

            // Sends a POST request to the backend.
            await sendPostRequestWithMultipleMessages(messagesToSendToBackend);
        }
    };


    // The following useEffect is used to scroll to the bottom of the text area.
    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
        }
    }, [textAreaRef]);

    // The following useEffect updates the text area value when the speech recognition transcript is updated.
    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.value = transcript;
            dispatch(handleCharCount(transcript))
        }
    }, [transcript, dispatch]);

    return (
        <div className="textarea-wrapper">
            <textarea
                ref={textAreaRef}
                placeholder={isLoadingMessage ? "Loading message..." : "Type message here"}
                disabled={isLoadingMessage}
                onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        sendChatMessage();
                    }
                }}
                onChange={(e) => {
                    dispatch(handleCharCount(e.target.value))
                }}
            />
            <div className="chat-button-wrapper">
                <button
                    className="chat-button"
                    disabled={isLoadingMessage || listening}
                    onClick={() => {
                        if (hasText) {
                            sendChatMessage()
                        } else if (!isMicrophoneAvailable) {
                            window.alert("Microphone is not available :(");
                        } else if (!browserSupportsSpeechRecognition) {
                            window.alert("Speech Recognition API is not supported in your browser :(");
                        } else {
                            listen(speechRecognitionLanguageCode);
                        }
                    }}
                >
                    {isLoadingMessage ?
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                        :
                        <FontAwesomeIcon icon={hasText ? faPaperPlane : faMicrophone} />
                    }
                </button>
            </div>
        </div>
    );
};