import axios from 'axios';
import { GETALLTENANTS, MODEL, SAVETENANT, VALIDATEPASSWORD } from 'src/constants/constants';
import { composeBaseURL, handleErrors, composePath } from 'src/common/commonUtils';
import { ModelInfoState } from 'src/store/slices/modelSlice';

// Define the base URL for your API
const BASEAPI_URL = MODEL;

// Create an Axios instance
const api = axios.create({
    baseURL: composeBaseURL(BASEAPI_URL),
    headers: { 'Content-Type': 'application/json' },
});

// API functions
export const apiGetModelByTenantName = async (tenantName: string): Promise<any> => {
    try {
        const response = await api.get(tenantName);
        return response.data;
    } catch (error) {
        throw new Error(handleErrors(error));
    }
};

export const apiSaveJson = async (modelData: ModelInfoState): Promise<any> => {
    try {

        const response = await api.post(composePath(SAVETENANT), modelData);
        return response.data;
    } catch (error) {
        throw new Error(handleErrors(error));
    }
};

export const apiGetAllTenants = async (): Promise<any> => {
    try {

        const response = await api.get(composePath(GETALLTENANTS));
        return response.data;
    } catch (error) {
        throw new Error(handleErrors(error));
    }
};

export const apiValidatePassword = async (password: string): Promise<any> => {
    try {

        const response = await api.post(composePath(VALIDATEPASSWORD), { password });
        return response.data;
    } catch (error) {
        throw new Error(handleErrors(error));
    }
};

export const apiDeleteTenant = async (tenantName: string): Promise<any> => {
    try {
        const response = await api.delete(tenantName);
        return response.data;
    } catch (error) {
        throw new Error(handleErrors(error));
    }
};
